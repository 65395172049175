import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config';
import { Sidebar } from '../../Sidebar/Sidebar'
import { Editor } from '@tinymce/tinymce-react';
// import ReactQuill from "react-quill";
// import EditorToolbar, { modules, formats } from "./EditorToolbar";
// import "react-quill/dist/quill.snow.css";
// import "./TextEditor.css";


export const EditService = () => {

    const [servicecategory, setServiceCategory] = useState();
    const [servicename, setServiceName] = useState();
    const [serviceprice, setServicePrice] = useState();
    const [serviceheading, setServiceHeading] = useState();
    const [servicedescription, setServiceDescription] = useState();
    const [servicestatus, setServiceStatus] = useState();
    const [serviceimage, setServiceImage] = useState();
    const navigate = useNavigate();
    const { id } = useParams();

    console.log("serviceCategory", servicecategory)
    useEffect(() => {
        getdata();
    }, [])



    // get data First---
    const getdata = async () => {
        const { data } = await axiosInstance.get(`/api/service/${id}`)
        setServiceCategory(data.ServiceCategory)
        setServiceName(data.ServiceName)
        setServicePrice(data.ServicePrice)
        setServiceHeading(data.ServiceHeading)
        setServiceDescription(data.ServiceDescription)
        setServiceStatus(data.ServiceStatus)
        setServiceImage(data.ServiceImage)

    }

    const handleUserdataSubmit = async (e) => {
        e.preventDefault();
        const servicedata = new FormData();
        servicedata.append("ServiceCategory", servicecategory)
        servicedata.append("ServiceName", servicename)
        servicedata.append("ServicePrice", serviceprice)
        servicedata.append("ServiceHeading", serviceheading)
        servicedata.append("ServiceDescription", servicedescription)
        servicedata.append("ServiceStatus", servicestatus)
        servicedata.append("Userimg", serviceimage)


        try {
            const { data } = await axiosInstance.put(`/api/service/${id}`, servicedata, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            alert("vendors data Created!")
            navigate("/admin/service")

        } catch (error) {
            alert(error)

        }

        navigate('/admin/service')
    }


    // const handleUserdataSubmit = async (e) => {

    //         e.preventDefault();
    //         try {
    //             await axiosInstance.put(`/api/city/${id}`, {

    //                 "CityName": cityname

    //             });
    //         } catch (err) {
    //             console.log(err)
    //         }
    //         console.log(cityname)
    //         navigate('/admin/city')

    // }

    return (
        <div>

            <Sidebar />

            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#12192c' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className="container ">
                    <div className="text-center">
                        <h2>Edit Service</h2>
                    </div>
                    <div className="row justify-content-center py-2">
                        <div className="col-lg-8">

                            <form onSubmit={handleUserdataSubmit}>
                                <div className="row">
                                    <div className="col">
                                        <select class="form-select" value={servicecategory} onChange={e => setServiceCategory(e.target.value)} aria-label="Default select example">
                                            <option selected>Service Category</option>
                                            <option value="Architecture">Architecture</option>
                                            <option value="Landscape">Landscape</option>
                                            <option value="Vastu Consultancy">Vastu Consultancy</option>
                                            <option value="Modular Kitchen">Modular Kitchen </option>
                                        </select>
                                    </div>
                                </div><br />
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Name</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={servicename} onChange={e => setServiceName(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Value</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={serviceprice} onChange={e => setServicePrice(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Heading</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={serviceheading} onChange={e => setServiceHeading(e.target.value)} aria-describedby="emailHelp" />
                                    </div>

                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Status</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={servicestatus} onChange={e => setServiceStatus(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Image</label>
                                        <input type="file" class="form-control" onChange={e => setServiceImage(e.target.files[0])} id="inputGroupFile01" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service description</label>
                                        {/* <div id="div_editor4">
                                            
                                        </div> */}
                                        <Editor
                                            init={{

                                                plugins: 'link image code',
                                                pagebreak_split_block: true
                                            }}
                                           
                                            data={servicedescription}
                                            value={servicedescription}

                                            onEditorChange={e => setServiceDescription(e)}

                                        />
                                       
                                        {/* <input type="text" class="form-control" id="exampleInputEmail1" value={servicedescription} onChange={e => setServiceDescription(e.target.value)} aria-describedby="emailHelp" /> */}
                                    </div>
                                </div>

                                <button type="submit" class="btn mybtn">Submit</button>
                            </form>
                        </div>
                    </div>


                </div>
            </section>

        </div>
    )
}
