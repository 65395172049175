import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../Sidebar/sidebar.css'
// import { drop } from './Main'
// import { toggleSideNav } from './Main'

export const Sidebar = () => {


    const logout = ()=>{
        localStorage.clear();
        alert('remove successfuuly')
    }

    const myfun = () => {
        // --------- Toggle Side Nav --------
        const menuBtn = document.querySelector("#nav-toggle");
        const sideNav = document.querySelector("#side-nav");
        const push = document.querySelector(".push");


        if (sideNav.style.width == "5.6rem" || sideNav.style.width == "") {
            sideNav.style.width = "16rem";
            push.style.marginLeft = "16rem";
        } else {
            // close side nav
            sideNav.style.width = "5.6rem";
            push.style.marginLeft = "0rem";
            // close all opened sub menus
            document.querySelectorAll('.nav__drop').forEach(drop => drop.style.height = '0px');

        }
    }


    return (
        <div>

            
            <div class="sidenav-container" id="side-nav">
                <nav class="nav">
                    <div class="nav__brand">
                        <div class="nav__icon nav__icon--menu" id="nav-toggle">
                            <ion-icon name="menu-outline" onClick={myfun} ></ion-icon>
                        </div>

                        <a href="/" class="nav__brand-logo">
                           Todor
                        </a>
                    </div>

                    <hr />

                    <ul class="nav__list px-0">
                        <li class="nav__item">
                            <Link to="/admin/user" class="nav__link active">
                                <ion-icon name="people-outline" class="nav__icon"></ion-icon>
                                <span class="nav__name">Users</span>
                            </Link>
                        </li>
                        <li class="nav__item">
                            <Link to="/admin/product" class="nav__link">
                                <ion-icon name="earth-outline" class="toggle-icon"></ion-icon>
                                <span class="nav__name">Project</span>
                            </Link>
                        </li>
                        <li class="nav__item">
                            <Link to="/admin/service" class="nav__link">
                                <ion-icon name="text-outline" class="nav__icon"></ion-icon>
                                <span class="nav__name">Services</span>
                            </Link>
                        </li>

                        <li class="nav__item">
                            <Link to="/admin/review" class="nav__link">
                                <ion-icon name="business-outline" class="nav__icon"></ion-icon>
                                <span class="nav__name">Reviews</span>
                            </Link>
                        </li>


                        <li class="nav__item">
                            <a href="/" class="nav__link" onClick={logout}>
                                <ion-icon name="settings-outline" class="nav__icon"></ion-icon>
                                <span class="nav__name">LogOut</span>
                            </a>
                        </li>
                    </ul>

                    <hr />

                    <div class="nav__user">
                        <div class="nav__user-image">
                            <img src="https://wallpapercave.com/uwp/uwp358219.jpeg" width="100%" alt="profile" />
                        </div>

                        <div class="nav__user-info">
                            <div class="nav__user-info-name">Todor</div>
                            <div class="nav__user-info-email">todor@gmail.com</div>
                        </div>
                    </div>
                </nav>
            </div>

        </div>
    )
}
