import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config';
import { Sidebar } from '../../Sidebar/Sidebar'

export const Edituser = () => {

    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [number, setNumber] = useState();
    const [password, setPassword] = useState();

    const navigate = useNavigate();
    const { id } = useParams();

    // get data First---
    const getdata = async () => {
        const { data } = await axiosInstance.get(`/api/user/${id}`)
        console.log("editdata", data)
        setFirstname(data.FirstName)
        setLastname(data.LastName)
        setUsername(data.UserName)
        setEmail(data.Email)
        setNumber(data.Number)
        setPassword(data.Password)

        console.log("iddata",data)
    }

    useEffect(() => {
        getdata();
    }, [])

    const handleUserdataSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.put(`/api/user/${id}`, {

                "FirstName": firstname,
                "LastName": lastname,
                "UserName": username,
                "Email": email,
                "Password": password,
                "Number": number

            });
        } catch (err) {
            console.log(err)
        }
        navigate('/admin/user')
    }


    return (
        <div>
            <Sidebar />

            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#12192c' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className="container ">
                    <div className="text-center">
                        <h2>Add User</h2>
                    </div>
                    <div className="row justify-content-center py-2">
                        <div className="col-lg-8 ">

                            <form onSubmit={handleUserdataSubmit}>
                                <div class="mb-1">
                                    <label for="exampleInputEmail1" class="form-label">First Name</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" value={firstname} onChange={e => setFirstname(e.target.value)} aria-describedby="emailHelp" />
                                </div>
                                <div class="mb-1">
                                    <label for="exampleInputPassword1" class="form-label">Last Name</label>
                                    <input type="text" class="form-control" value={lastname} onChange={e => setLastname(e.target.value)} id="exampleInputPassword1" />
                                </div>
                                <div class="mb-1">
                                    <label for="exampleInputPassword1" class="form-label">User Name</label>
                                    <input type="text" class="form-control" value={username} onChange={e => setUsername(e.target.value)} id="exampleInputPassword1" />
                                </div>
                                <div class="mb-1">
                                    <label for="exampleInputPassword1" class="form-label">Email</label>
                                    <input type="text" class="form-control" value={email} onChange={e => setEmail(e.target.value)} id="exampleInputPassword1" />
                                </div>
                                <div class="mb-1">
                                    <label for="exampleInputPassword1" class="form-label">Number</label>
                                    <input type="text" class="form-control" value={number} onChange={e => setNumber(e.target.value)} id="exampleInputPassword1" />
                                </div>
                                <div class="mb-1">
                                    <label for="exampleInputPassword1" class="form-label">Password</label>
                                    <input type="text" class="form-control" value={password} onChange={e => setPassword(e.target.value)} id="exampleInputPassword1" />
                                </div>






                                <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>


                </div>
            </section>
        </div>
    )
}
