import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../../config'
import { Sidebar } from '../../Sidebar/Sidebar';
 

export const UserDashboard = () => {
    const [user, setUser] = useState();
    const [del, setdel] = useState(false)

    useEffect(() => {
        getdata();
    },[!del])

    
    const getdata = async () => {
        const { data } = await axiosInstance.get("/api/user")
        console.log("user", data)
    
        setUser(data)

    }
    const deleteuser = async(id) => {
        var answer = window.confirm("Are You Sure!");
        if(answer){
            await axiosInstance.delete(`/api/user/${id}`);
            alert("deleted",id);
            setdel(!del);
            
        }
    }


    return (
        <div>

            <Sidebar />
            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#010100' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className=" py-5 container">
                    <div>
                        <Link className="btn mybtn " to="/admin/add-user">Add User</Link>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">FirstName</th>
                                <th scope="col">LastName</th>
                                <th scope="col">UserName</th>
                                <th scope="col">Email</th>
                                <th scope="col">Number</th>
                                <th scope="col">Password</th>
                                <th scope="col" colspan="3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                user && user.map((u, no) => (
                                    <>
                                        <tr>
                                            <th scope="row">{no + 1}</th>
                                            <td>{u.FirstName}</td>
                                            <td>{u.LastName}</td>
                                            <td>{u.UserName}</td>
                                            <td>{u.Email}</td>
                                            <td>{u.Number}</td>
                                            <td>{u.Password}</td>
                                            <td>
                                                <Link className="btn btn-success mx-2" to={`/admin/edit-user/${u._id}`} > <ion-icon name="create-outline"></ion-icon> </Link>
                                                <a href="#" className="btn btn-danger" onClick={() => deleteuser(u._id)}><ion-icon name="trash-outline"></ion-icon></a>
                                            </td>
                                        </tr>
                                    </>

                                ))
                            }


                        </tbody>
                    </table>
                </div>
            </section>


        </div>
    )
}
