import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config';
import { Sidebar } from '../../Sidebar/Sidebar'
import { Editor } from '@tinymce/tinymce-react';

export const AddProduct = () => {

    const [productname, setProductName] = useState();
    const [productcategory, setProductCategory] = useState();
    const [productprice, setProductPrice] = useState();
    const [productheading, setProductHeading] = useState();
    const [productdescription, setProductDescription] = useState();
    const [productstatus, setProductStatus] = useState();
    const [productimage, setProductImage] = useState();
    
    const [productclient, setProductClient] = useState();
    const [productlocation, setProductLocation] = useState();
    const [productsize, setProductSize] = useState();

    const navigate = useNavigate();
    // const { id } = req.params.id;


    const handleUserdataSubmit = async (e) => {
        e.preventDefault();
        const productdata = new FormData();
        productdata.append("ProductName", productname)
        productdata.append("ProductCategory", productcategory)
        productdata.append("ProductPrice", productprice)
        productdata.append("ProductHeading", productheading)
        productdata.append("ProductDescription", productdescription)
        productdata.append("ProductStatus", productstatus)

        productdata.append("ProductClient", productclient)
        productdata.append("ProductLocation", productlocation)
        productdata.append("ProductSize", productsize)

        productdata.append("Userimg", productimage)


        try {
            const { data } = await axiosInstance.post(`/api/product/`, productdata, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            alert("vendors data Created!")
            navigate("/admin/product")

        } catch (error) {
            alert(error)

        }
        // const { data } = axiosInstance.post('/api/vendors', {

        //     "VendorName": vendorname,
        //     "VendorNumber": vendornumber,
        //     "VendorEmail": vendoremail,
        //     "VendorImage": vendorimage,
        //     "VendorLocation": vendorlocation,
        //     "VendorHighPriceRange": vendorhighprice,
        //     "VendorLowPriceRange": vendorlowprice,
        //     "VendorStatus": vendorstatus,
        //     "VendorJoiningDate": vendordate,
        //     "CityDetails": citydetails,
        //     "VendorTypeDetails": vendortype,


        // });
        // console.log("AddedVendor", data)
        navigate('/admin/product')
    }

    return (
        <div>

            <Sidebar />

            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#12192c' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className="container ">
                    <div className="text-center">
                        <h2>Add User</h2>
                    </div>
                    <div className="row justify-content-center py-2">
                        <div className="col-lg-8 ">

                            <form onSubmit={handleUserdataSubmit}>
                                <div className="row">
                                    <div className="col">
                                        <select class="form-select" value={productcategory} onChange={e => setProductCategory(e.target.value)} aria-label="Default select example">
                                            <option selected>Product Category</option>
                                            <option value="Interior Design">Interior Design</option>
                                            <option value="Alivation">Alivation</option>
                                            <option value="Courtyards Design">Courtyards Design</option>
                                        </select>
                                    </div>
                                </div><br />
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Name</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productname} onChange={e => setProductName(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Prduct Value</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productprice} onChange={e => setProductPrice(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Heading</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productheading} onChange={e => setProductHeading(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Client</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productclient} onChange={e => setProductClient(e.target.value)} aria-describedby="emailHelp" />
                                    </div>

                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Location</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productlocation} onChange={e => setProductLocation(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Size</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productsize} onChange={e => setProductSize(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Status</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={productstatus} onChange={e => setProductStatus(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Product Image</label>
                                        <input type="file" class="form-control" onChange={e => setProductImage(e.target.files[0])} id="inputGroupFile01" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">product description</label>
                                        <Editor
                                             
                                            onEditorChange={e => setProductDescription(e)}
                                            initialValue="<p>This is the initial content of the editor</p>"
                                        />
                                        {/* <input type="text" class="form-control" id="exampleInputEmail1" value={productdescription} onChange={e => setProductDescription(e.target.value)} aria-describedby="emailHelp" /> */}
                                    </div>
                                </div>



                                <button type="submit" class="btn mybtn">Submit</button>
                            </form>
                        </div>
                    </div>


                </div>
            </section>

        </div>
    )
}
