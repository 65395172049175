import { removeSubsets } from 'domutils'
import React, { useState, useEffect } from 'react'
import { axiosInstance } from '../config'
import { Footer } from './Footer'
import { Header } from './Header'

export const Reviews = () => {

    const [myreviews, setMyreviews] = useState()



    useEffect(() => {
        getdata();
    }, [])

    const getdata = async () => {
        const { data } = await axiosInstance.get('/api/reviews/')
        console.log("myreview", data)

        setMyreviews(data);
    }

    return (
        <div>


            <Header />

            <section className="product_banner d-flex justify-content-center align-items-center text-white" style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${require(`./images/interior2.jpg`)})` }} >
                <h1><b>What Our Clients Says</b></h1>
            </section>




            {/* Review---s */}
            <section className="reviews py-5">
                <div className="container py-5">
                    <div className="row">
                        {
                            myreviews && myreviews?.map((review) => (
                                <>

                                    <div className="col-lg-12 ">
                                        <div class="card mb-3" style={{ backgroundImage: 'linear-gradient(to bottom right, #A876FF, #449DF8)' }}>
                                            <div class="row g-0">
                                                <div class="col-md-4 p-2">
                                                    <div id={`${review.ClientName.replaceAll(' ','aj').split(".").join("")}`} class="carousel slide carousel-fade " data-bs-ride="carousel">
                                                        <div class="carousel-inner">
                                                            <div class="carousel-item active">
                                                                <img src={`https://tudorarchitect.herokuapp.com/uploadFiles/${review.ClientImage[0]}`} class="d-block w-100" style={{ minHeight: '60vh' }} />
                                                            </div>
                                                            {
                                                                review.ClientImage && review.ClientImage?.map((img) => (
                                                                    <>
                                                                        <div class="carousel-item ">
                                                                            <img src={`https://tudorarchitect.herokuapp.com/uploadFiles/${img}`} style={{ minHeight: '60vh' }} class="d-block w-100 rounded" alt="..." />
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                        <button class="carousel-control-prev" type="button" data-bs-target={`#${review.ClientName.replaceAll(' ','aj').split(".").join("")}`} data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                        <button class="carousel-control-next" type="button" data-bs-target={`#${review.ClientName.replaceAll(' ','aj').split(".").join("")}`} data-bs-slide="next">
                                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-8 ">
                                                    <div class="card-body text-white py-5">
                                                        <h5 class="card-title">{review.ClientName}</h5>
                                                        <p class="card-text py-4" ><b>
                                                            {review.ClientReview}
                                                        </b></p>
                                                        <p class="card-text py-4"><small class="text-white">Review Date: {review.createdAt.slice(0,10).split("-").reverse().join("-")}</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            ))
                        }
                    </div>
                </div>
            </section >
            {/* Review---s */}




            < Footer />

        </div >
    )
}
