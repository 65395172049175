import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config';
import { Sidebar } from '../../Sidebar/Sidebar'
import { Editor } from '@tinymce/tinymce-react';

export const Edit_reviews = () => {

    const [clientname, setClientName] = useState();
    const [clientreviews, setClientReviews] = useState();
    const [clientimage, setClientImage] = useState();
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        getdata();
    }, [])

    // get data First---
    const getdata = async () => {
        const { data } = await axiosInstance.get(`/api/reviews/${id}`)
        setClientName(data.ClientName)
        setClientReviews(data.ClientReview)
        setClientImage(data.ClientImage)
        // for (let i = 0; i <= data.SetClientImage.length; i++){
        //     setClientImage(data.SetClientImage[i])
        // }
        

    }

    const handleUserdataSubmit = async (e) => {
        e.preventDefault();
        const servicedata = new FormData();
        servicedata.append("ClientName", clientname)
        servicedata.append("ClientReviews", clientreviews)
        // servicedata.append("profile", clientprofile)
        for (let i = 0; i <= clientimage.length; i++) {
            servicedata.append("serviceimg", clientimage[i]);
        }

        try {
            const { data } = await axiosInstance.put(`/api/reviews/${id}`, servicedata, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            console.log("reviews", servicedata)
            alert("vendors data Created!")
            navigate("/admin/review")

        } catch (error) {
            alert(error)

        }

        navigate('/admin/review')
    }

    return (
        <div>


            <Sidebar />

            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#12192c' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className="container ">
                    <div className="text-center">
                        <h2>Edit Service</h2>
                    </div>
                    <div className="row justify-content-center py-2">
                        <div className="col-lg-8">

                            <form onSubmit={handleUserdataSubmit}>

                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Name</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={clientname} onChange={e => setClientName(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Value</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" value={clientreviews} onChange={e => setClientReviews(e.target.value)} aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1" class="form-label">Service Image</label>
                                        <input type="file" class="form-control" onChange={e => setClientImage(e.target.files)} multiple id="inputGroupFile01" />
                                    </div>
                                </div>


                                <button type="submit" class="btn mybtn">Submit</button>
                            </form>
                        </div>
                    </div>


                </div>
            </section>


        </div>
    )
}
