import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../../config'
import { Sidebar } from '../../Sidebar/Sidebar'

export const Reviews_Dashboard = () => {

    const [reviews, setReviews] = useState();
    const [del, setdel] = useState(false)


    useEffect(() => {
        getdata();
    }, [!del])

    const getdata = async () => {
        const { data } = await axiosInstance.get("/api/reviews/");
        console.log("getdata", data)
        setReviews(data)
    }
    const deleteuser = async (id)=>{
        const answer = window.confirm("Are You Sure !")
        if(answer){
            const {data} = await axiosInstance.delete(`/api/reviews/${id}`)
            alert("deleted permanently")
            setdel(data)

        }
    }
    return (
        <div>




            <Sidebar />
            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#e72e77' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className=" py-5 container">
                    <div>
                        <Link className="btn mybtn " to="/admin/add-review">Add Service</Link>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Client Name</th>
                                <th scope="col">Client Reviews</th>
                                <th scope="col">Client Image</th>
                                <th scope="col" colspan="3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reviews && reviews.map((c, no) => (
                                    <>
                                        <tr>
                                            <th scope="row">{no + 1}</th>
                                            <td>{c.ClientName}</td>
                                            <td>{c.ClientReview}</td>
                                            {/* <td>{c.ClientProfile}</td> */}
                                            <td>
                                                {c.ClientImage && c.ClientImage?.map((img) => (
                                                    <>
                                                        <div >
                                                            {img}
                                                        </div>
                                                    </>
                                                ))}
                                            </td>
                                            <td>
                                                <Link className="btn btn-success mx-2" to={`/admin/edit-review/${c._id}`} > <ion-icon name="create-outline"></ion-icon> </Link>
                                                <a href="#" className="btn btn-danger" onClick={() => deleteuser(c._id)}><ion-icon name="trash-outline"></ion-icon></a>
                                            </td>
                                        </tr>
                                    </>
                                ))
                            }


                        </tbody>
                    </table>
                </div>
            </section>






        </div>
    )
}
