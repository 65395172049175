import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../../config'
import { Sidebar } from '../../Sidebar/Sidebar'
import parse from 'html-react-parser';

export const ProductDashboard = () => {

    const [city, setCity] = useState();
    const [del, setdel] = useState();

    useEffect(() => {
        getdata();
    }, [!del])

    const getdata = async()=>{

        const {data} = await axiosInstance.get("/api/product");
        console.log("productdata",data);
        setCity(data);

    }
    const deleteuser =async (id)=>{
        const answer = window.confirm("Are You Sure !")
        if(answer){
            const {data} = await axiosInstance.delete(`/api/product/${id}`)
            alert("deleted permanently")
            setdel(data)

        }
    }


    return (
        <div>

            <Sidebar />

            <section className="push " style={{ position: 'relative', transition: ' margin-left .5s' }}>
                <div className=" py-4 " style={{ position: 'relative', transition: ' margin-left .5s', backgroundColor: '#e72e77' }}>
                    <div className="container text-white">
                        <h2>Logo</h2>
                    </div>
                </div>
                <div className=" py-5 container">
                    <div>
                        <Link className="btn mybtn " to="/admin/add-product">Add Product</Link>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Product Category</th>
                                <th scope="col">Product Value</th>
                                <th scope="col">Product Heading</th>
                                {/* <th scope="col">Product Description</th> */}
                                <th scope="col">Product Status</th>

                                <th scope="col">Product Client</th>
                                <th scope="col">Product Location</th>
                                <th scope="col">Product Size</th>

                                <th scope="col">Product Image</th>
                                <th scope="col" colspan="3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                city && city.map((c, no) => (
                                    <>
                                        <tr>
                                            <th scope="row">{no + 1}</th>
                                            <td>{c.ProductName}</td>
                                            <td>{c.ProductCategory}</td>
                                            <td>{c.ProductPrice}</td>

                                            <td>{c.ProductHeading}</td>
                                            <td>{c.ProductStatus}</td>
                                            <td>{c.ProductClient}</td>
                                            <td>{c.ProductLocation}</td>
                                            <td>{c.ProductSize}</td>

                                            {/* <td>{parse(c.ProductDescription)}</td> */}
                                            <td>{c.ProductImage}</td>
                                            <td>
                                                <Link className="btn btn-success mx-2" to={`/admin/edit-product/${c._id}`} > <ion-icon name="create-outline"></ion-icon> </Link>
                                                <a href="#" className="btn btn-danger" onClick={() => deleteuser(c._id)}><ion-icon name="trash-outline"></ion-icon></a>
                                            </td>
                                        </tr>
                                    </>

                                ))
                            }


                        </tbody>
                    </table>
                </div>
            </section>

        </div>
    )
}
